module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Remote Jobs Club: Work from Anywhere","short_name":"Remote Jobs Club","start_url":"/","background_color":"#EF633D","theme_color":"#EF633D","display":"minimal-ui","icon":"src/images/logo_square.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"b6792c68d8e95e95207c99671324b0e1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-570069-40","head":false,"anonymize":false,"respectDNT":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
